require("@rails/ujs").start()
global.Rails = Rails

require("turbolinks").start()
require("@rails/activestorage").start()

import 'bootstrap'
import 'assets/javascripts/vendor/bootstrap-notify.min.js'

import 'tablesorter'
import 'jquery-validation'

import flatpickr from 'flatpickr'
import 'flatpickr/dist/l10n/et.js'

import 'select2/dist/js/select2.full'
import 'assets/javascripts/vendor/maximize-select2-height.min'

import '../javascripts/application.coffee'
import '../javascripts/crud'
import '../javascripts/controllers'

import '../stylesheets/vendor'

# import 'hds/dist/css/hds-styles.css'
import '../stylesheets/custom-hds-styles'

# import 'assets/javascripts/jquery-validate/messages_et.js'
import 'jquery-validation/dist/localization/messages_et'
import 'select2/dist/js/i18n/et'

import '../stylesheets/application'
