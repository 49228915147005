#
# Validations
#

# mspassword: "Parool peab sisaldama ainult inglise keele tähestiku tähti. Microsoft AD poolt mittelubatud sümbolid: {0}",

$.validator.addMethod 'nr', ((value, element) ->
  @optional(element) or /^[\d\.\,]+$/.test(value)
), 'Please enter a valid number.'

$.validator.addMethod 'mspassword', ((value, element) ->
  @optional(element) or /^[a-zA-Z0-9#!@$%^&*()\-_+={}[\]|\\:;"'<>,.?\/ ]+$/.test(value)
), (para, element) ->
  chrs = $(element).val()
  notallowed = []
  chrs.split('').forEach (chr, index, array) ->
    return if /^[a-zA-Z0-9#!@$%^&*()\-_+={}[\]|\\:;"'<>,.?\/ ]+$/.test(chr)
    return if notallowed.includes(chr)
    notallowed.push(chr)
  notallowed = notallowed.sort().join(' ')
  $.validator.format para, notallowed

$(document).on 'turbolinks:load', ->
  # jquery-validate: client side validate all forms
  $('form').each ->
    $(this).validate({
      errorClass: 'error is-invalid',
      errorElement: 'div',
      highlight: (element, errorClass, validClass) ->
        $(element).addClass(errorClass).removeClass(validClass)
        $(element).next('div').addClass('invalid-feedback')
      unhighlight: (element, errorClass, validClass) ->
        $(element).removeClass(errorClass).addClass(validClass)
        $(element.form).next('div').removeClass('invalid-feedback')
      errorPlacement: (error, element) ->
        # It doesn't add class by default, so need to override it.
        error.addClass('invalid-feedback')
        error.insertAfter(element)
    })

#
# generic
#

# Toggle element visibility
$(document).on 'mousedown', '.js-toggle', (ev) ->
  target = $(this) # cannot use ev.target because it might reflect to child elements as well
  el = $(target.data('target'))
  if el.length
    if el.is(':visible')
      el.hide()
    else
      target.hide() if target.data('selfdestroy')
      el.show()

$(document).on 'click', '.js-toggle', (ev) ->
  ev.preventDefault()
  ev.stopPropagation()


# Enable tooltips
$ ->
  $('[data-toggle=tooltip]').tooltip({html:true})

$ ->
  $(".search_field").select2
    placeholder: "Select"
    allowClear: true
    dropdownAutoWidth : true
    maximumInputLength: 10
  return

$ ->
  $(".typeahead").select2
    placeholder: "Select"
    allowClear: true
    dropdownAutoWidth : true
    maximumInputLength: 10
    width: 'resolve'
  return

# Mass-selection
$(document).on 'turbolinks:load', ->
  window.selected_ad_users = {} if !window.selected_ad_users
  $('#js_select_all').on 'click', ->
    checked = this.checked
    $('.js-mass-select').each ->
      $(this).prop('checked', checked)
      window.selected_ad_users[this.value] = checked
      return true # to continue .each loop
  $('.js-mass-select').on 'click', ->
    window.selected_ad_users[this.value] = this.checked
    return true
  $('#new_ad_update').on 'submit', ->
    ids = Object.keys(window.selected_ad_users).filter (id) ->
      window.selected_ad_users[id]
    $('input[name="selected_ids"]', this).prop('value', ids)
    return true

# user_remember_me
$(document).on 'turbolinks:load', ->
  if $('.remember_me').length
    $('.remember_me').on 'change', ->
      $.ajax
        url: '/en/remember_cookie'
        type: if $(this).is(':checked') then 'POST' else 'DELETE'
        headers: {
          'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')
        }
      # Sync other remember_me checkboxes
      $('.remember_me').prop('checked', $(this).is(':checked'))

#
# group
#
jQuery ->
  check_entitlement = (check_box, name_field)->
    if check_box.is(':checked')
      name_field.prop "disabled", false
    else
      name_field.prop "disabled", true
    return

  # Capybara::Poltergeist::JavascriptError:
  # Error: Syntax error, unrecognized expression: input[name='group[is_entitlement]'
  #
  # $("input[name='group[is_entitlement]'").on 'change', ->
    # check_box = $(this)
    # name_field = $(this).closest('form').find('input[name="group[eduperson_entitlement_name]"]')
    # check_entitlement(check_box, name_field)

  # $(".group_button").on 'click', ->
    # check_box = $($(this).data('target')).find('input[name="group[is_entitlement]"]')
    # name_field = $($(this).data('target')).find('input[name="group[eduperson_entitlement_name]"]')
    # check_entitlement(check_box, name_field)

#
# Nested forms
#
jQuery ->
  $('form').on 'click', '.remove_fields', (event) ->
    $(this).prev('input[type=hidden]').val('1')
    $(this).closest('fieldset').hide()
    event.preventDefault()

  $('form').on 'click', '.add_fields', (event) ->
    time = new Date().getTime()
    regexp = new RegExp($(this).data('id'), 'g')
    $(this).before($(this).data('fields').replace(regexp, time))
    event.preventDefault()

#
# People
#
jQuery ->
	$(".person_emails").on 'click', ".primarybutton", ->
		$(this).parents(".person_emails").find('input.primarybutton').prop('checked', false)
		$(this).prop('checked', true)

	$(".person_phone_numbers").on 'click', ".primarybutton", ->
		$(this).parents(".person_phone_numbers").find('input.primarybutton').prop('checked', false)
		$(this).prop('checked', true)


#
# Users
#
window.check_option = (element, val) ->
  e = $(element)
  if val is ""
    $(e).closest("form").find(".person_fields :input").prop "disabled", false
    $(e).closest("form").find(".person_fields").show()
  else
    $(e).closest("form").find(".person_fields :input").prop "disabled", true
    $(e).closest("form").find(".person_fields").hide()
  return

# jQuery ->
  # require_password = (password_field) ->
    # if password_field.val().length is 0
      # password_field.prop "required", false
      # password_field.closest("form").find("input[name='user[password_confirmation]']").prop "required", false
    # else
      # password_field.prop "required", true
      # password_field.closest("form").find("input[name='user[password_confirmation]']").prop "required", true
    # return

  # $("input[name='person[system_account]'").on 'change', ->
    # if $(this).is(':checked')
      # $(this).closest('form').find('input[name="person[personal_unique_id]"]').prop "disabled", true
      # $(this).closest('form').find('select[name="person[personal_unique_id_type]"]').prop "disabled", true
      # $(this).closest('form').find(".person_data_fields").hide()
    # else
      # $(this).closest('form').find('input[name="person[personal_unique_id]"]').prop "disabled", false
      # $(this).closest('form').find('select[name="person[personal_unique_id_type]"]').prop "disabled", false
      # $(this).closest('form').find(".person_data_fields").show()
    # return

  # $(".user_password input[name='user[password]']").hidePassword true
  # $(".edit_password input[name='user[password]']").hidePassword true

  # $("input.edit_password[name='user[password]']").on "change", ->
    # require_password $(this)


#
# Dynamic flash messages
#
@flash_data = (data) ->
  return flash_notice(data.notice) if data.notice
  return flash_alert(data.alert) if data.alert

@flash_alert = (msg) ->
  $.notify({
    message: msg
  },{
    type: 'warning',
    placement: {
      from: "top",
      align: "center"
    },
    offset: {
      x: 20,
      y: 4
    }
  })

@flash_notice = (msg) ->
  $.notify({
    message: msg
  },{
    type: 'success',
    placement: {
      from: "top",
      align: "center"
    },
    offset: {
      x: 20,
      y: 4
    }
  })

@sticky_notice = (msg, url) ->
  $.notify({
    message: msg,
    url: url
  },{
    type: 'warning',
    placement: {
      from: "top",
      align: "center"
    },
    offset: {
      x: 20,
      y: 4
    },
    delay: 599999
  })

$(document).on 'turbolinks:load', ->
  unless $('body').data('env') == 'test'
    if $('.js-alert-success').length
      flash_notice($('.js-alert-success').html())
      $('.js-alert-success').remove()

    if $('.js-alert-danger').length
      flash_alert($('.js-alert-danger').html())
      $('.js-alert-danger').remove()

    if $('.js-sticky-alert-success').length
      sticky_notice($('.js-sticky-alert-success').html())
      $('.js-sticky-alert-success').remove()

    if $('.alert.alert-danger').length
      flash_alert($('.alert.alert-danger li, .alert.alert-danger p').html())
      $('.alert.alert-danger').remove()


#
# Select2
#
# mark selected value as selected in html as well for turbolinks cache return
# otherwise returning from cache does not restore correct selected option
# $(document).on 'select2:select', '.js-select2, .js-select2-data', (ev) ->
#   el = $(ev.target)
#   if !el.attr('multiple')
#     el.find("option").removeAttr('selected') # somehow opiton:selected didn't work
#   # this low level search is needed because value might have invalid attributes
#   select = el[0] # get native dom element
#   i = select.options.length
#   while i-- > 0
#     if select.options[i].value == ev.params.data.id
#       $(select.options[i]).attr('selected','selected')
#   # somehow without trigger server didn't received changed value
#   el.val(ev.params.data.id).trigger("change")

# prepare page to be cached, all mess should be cleaned up before cache,
# otherwise visiting page from cache is broken baddly
$(document).on 'turbolinks:before-cache', ->
  $('.js-select2, .js-select2-data, .js-select2-tags, .js-select2-whodunnit').select2('destroy')

$(document).on 'turbolinks:load cocoon:after-insert', ->
  $('.js-select2').each ->
    $this = $(this)
    $this.select2
      theme: 'bootstrap'
      language: $('html').attr('lang')
      placeholder: ->
        $(this).data 'placeholder'
      tags: $this.hasClass('js-select2-tags') ? true : false
      width: '100%'

  $('.js-select2-tags').each ->
    $this = $(this)
    $this.select2(
      language: $('html').attr('lang')
      placeholder: ->
        $(this).data 'placeholder'
      tags: true
      width: '100%'
    ).maximizeSelect2Height()

  $('.js-select2-whodunnit').each ->
    $this = $(this)
    $this.select2
      theme: 'bootstrap'
      language: $('html').attr('lang')
      minimumInputLength: 3
      width: '100%'
      placeholder: ->
        $(this).data 'placeholder'
      ajax: {
        url: '/' + $('html').attr('lang') + '/whodunnit_users'
        cache: true
        dataType: 'json'
        delay: 250
        processResults: (whodunnit_users) ->
          {
            results: whodunnit_users.map (whodunnit) ->
              {
                id: whodunnit.id
                text: whodunnit.text
              }
           }
        }


#
# TABLESORTERS
#

# add .sorter-date-with-time to table column header
$.tablesorter.addParser
  id: 'date-with-time'
  is: (s) ->
    false
  format: (s) ->
    # format your data for normalization
    #12.12.200912:00
    try
      return s.substring(6, 10) + s.substring(3, 5) + s.substring(0, 2) + s.substring(10)
    catch err
      return ''
    return
  type: 'text'

$(document).on 'turbolinks:load js-tablesorter', ->
  if $('.js-tablesorter').length
    $(".js-tablesorter").tablesorter(
      sortInitialOrder: 'desc'
      dateFormat: 'ddmmyyyy'
      textExtraction: (node) ->
        # convert '1 345,30' to '1345.30' for sorting
        $(node).text().replace(/[,]/g, '.').replace(/[ ]/g, '')
    )

# All changes should be cleaned up before Turbolinks cache
$(document).on 'turbolinks:before-cache', ->
  if $('.js-tablesorter').length
    $(".js-tablesorter").trigger('destroy')


#
# CALENDAR
#

$(document).on 'turbolinks:load', ->
  flatpickr('.flatpickr-input', {
    locale: $('html').attr('lang'),
    dateFormat: 'd.m.Y',
    allowInput: true,
    wrap: true,
    nextArrow: "<i class='mdi mdi-chevron-right'></i>",
    prevArrow: "<i class='mdi mdi-chevron-left'></i>"
  })


#
# Clone
# 
$(document).on 'click', '.js-clone', (ev) ->
  ev.preventDefault()
  target = $(ev.target)
  targetDiv = $(target.data('target'))
  targetDiv.clone().prependTo(target).attr("id","new" + Math.random()).show()

#
# CKEDITOR
#

$(document).on 'turbolinks:load', ->
  if $('.js-editor').length
    $('.js-editor').each ->
      height = $(this).data('height') || 800
      CKEDITOR.replace(
        this,
        {
          height: height
          language: $('html').attr('lang')
          resize_dir: 'vertical'
          width: '794px'
          toolbar: [
            {
              name: 'tools',
              items: ['Maximize', 'ShowBlocks', 'Preview']
            }, {
              name: 'clipboard',
              items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']
            }, {
              name: 'editing',
              items: ['Find', 'Scayt']
            }, {
              name: 'editing-etc',
              items: ['SelectAll' ]
            }, {
              name: 'links',
              items: ['Link', 'Unlink', 'Anchor']
            }, {
              name: 'insert',
              # items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar', 'PageBreak']
              items: ['Table', 'HorizontalRule', 'SpecialChar', 'PageBreak']
            }, {
              name: 'etc',
              items: ['Templates']
            }, '/', {
              name: 'styles',
              items: ['Format', 'Font', 'FontSize']
            }, {
              name: 'basicstyles',
              items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat' ]
            }, {
              name: 'paragraph',
              items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
            }, {
              name: 'paragraph',
              items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ]
            }, {
              name: 'paragraph',
              items: [ 'TextColor', 'BGColor' ]
            }, {
              name: 'document',
              items: ['Source']
            }
          ]
        }
      )

$(document).on 'turbolinks:before-cache', ->
  if typeof CKEDITOR != 'undefined'
    for name of CKEDITOR.instances
      `name = name`
      CKEDITOR.instances[name].destroy(true)


#
# Smart-ID
#
$(document).on 'turbolinks:load', ->
  if $('.js-trigger-smartid').length
    form = $('.js-trigger-smartid')
    setTimeout (->
      form.trigger('submit.rails')
    ), 3000


#
# Mobile-ID
#
$(document).on 'turbolinks:load', ->
  if $('.js-trigger-mobileid').length
    form = $('.js-trigger-mobileid')
    setTimeout (->
      form.trigger('submit.rails')
    ), 3000


#
# Checkbox confirm
# 
$(document).on 'change', '.js-checkbox-confirm', (ev) ->
  target = $(this)
  alert(target.data('confirm')) if target.is(':checked')


#
# Ajax progress
# 
$(document).on 'submit', (ev) ->
  $('body').addClass('ajax-progress')


#
# Clock ticking
# 
clock_interval = 0
clock_reset = ->
  alertStr = $('.js-clock-reset').data('alert')
  extendStr = $('.js-clock-reset').data('extend')
  sessionEndedStr = $('.js-clock-reset').data('ended')
  timeout = $('.js-clock-reset').data('timeout')
  expiration = new Date()
  expiration.setSeconds(expiration.getSeconds() + timeout)
  expiration = Math.floor(expiration.getTime())
  clearInterval(clock_interval)
  alert_shown = false
  extend_shown = false
  inactivity_activated = false
  clock_interval = setInterval (->
    diff = expiration - new Date()
    seconds = Math.floor(diff / 1000)
    if seconds > timeout/5
      return
    if seconds <= 0
      seconds = 0

    if !alert_shown
      flash_alert(alertStr)
      alert_shown = true

    if !inactivity_activated
      inactivity_activated = true
      $(document).on 'mousemove keydown', (ev)->
        $(document).unbind('mousemove keydown')
        clock_ping()

    display = new Date(0)
    display.setSeconds(seconds)
    string = display.toISOString().substr(14, 5)

    $('.js-clock').text(string)
    if !extend_shown
      $('.js-clock-reset').text(extendStr).show()
      extend_shown = true
    if seconds <= 0
      clearInterval(clock_interval)
      $('.js-clock-reset').text(sessionEndedStr)
      $('.js-clock-reset').unbind('click')
      setTimeout (->
        # Give server time to overlap delay
        window.location.reload()
      ), 3000
  ), 1000

$(document).on 'turbolinks:load', ->
  if $('.js-clock').length
    clock_reset()

#
# Reset clock
#
$(document).on 'turbolinks:load', ->
  if $('.js-clock-reset').length
      $('.js-clock-reset').on 'click', (ev)->
        ev.preventDefault()
        ev.stopPropagation()
        clock_ping()

clock_ping = ->
  $.ajax
    url: '/en/remember_cookie'
    type: 'DELETE'
    headers: {
      'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')
    }
  .done (data) ->
    clock_reset()
    $('.js-clock').text('')
    $('.js-clock-reset').hide()

#
# Emulate hover on TAB navigation on checkboxes
#
$(document).on 'turbolinks:load', ->
  $('input.custom-control-input[type=checkbox]').on 'focusin focusout', () ->
    $(this).parent('.custom-control').toggleClass('focus-visible')
