#
# Flashes
#
global.flash_alert = (msg) ->
  $.notify({
    message: msg
  },{
    type: 'warning',
    width: '600px',
    placement: {
      from: "top",
      align: "center"
    },
    offset: {
      x: 20,
      y: 4
    }
  })

global.flash_notice = (msg) ->
  $.notify({
    message: msg
  },{
    type: 'success',
    width: '600px',
    placement: {
      from: "top",
      align: "center"
    },
    offset: {
      x: 20,
      y: 4
    }
  })

global.permanent_flash_notice = (msg, url) ->
  $.notify({
    message: msg,
    url: url
  },{
    type: 'warning',
    width: '600px',
    placement: {
      from: "top",
      align: "center"
    },
    offset: {
      x: 20,
      y: 4
    }
    delay: 599999
  })

global.sticky_notice = (msg, url) ->
  $.notify({
    message: msg,
    url: url
  },{
    type: 'warning',
    width: '600px',
    placement: {
      from: "top",
      align: "center"
    },
    offset: {
      x: 20,
      y: 4
    },
    delay: 599999
  })

$(document).on 'turbolinks:load', ->
  unless $('body').data('env') == 'test'
    if $('.js-alert-success').length
      flash_notice($('.js-alert-success').html())
      $('.js-alert-success').remove()

    if $('.js-alert-danger').length
      flash_alert($('.js-alert-danger').html())
      $('.js-alert-danger').remove()


# 
# Rail ajax defaults
#
second_ajax = false

$(document).on 'ajax:send', (ev) ->
  $('body').addClass('ajax-progress')

$(document).on 'ajax:complete', (ev) ->
  unless second_ajax
    $('body').removeClass('ajax-progress')

$(document).on 'ajax:error', (ev) ->
  permanent_flash_notice(ev.detail[2].responseText)


#
# Change Rails-ujs default dataType setting from script to html
#
$(document).on 'ajax:before', (ev)->
  $(ev.target).attr('data-type', 'html') unless $(ev.target).attr('data-type')


#
# Rails 5: Avoid submit double-click automatically
#
$(document).on 'ajax:before', (ev)->
  el = $(ev.target)
  return unless el.is('form')
  el.find(':submit').attr('data-disable', true)


#
# Jquery ajax defaults
#
$(document).on 'ajaxStart', (ev) ->
  $('body').addClass('ajax-progress')

$(document).on 'ajaxStop', (ev) ->
  unless second_ajax
    $('body').removeClass('ajax-progress')


#
# obj prepend '#' if missing
#
obj = (el, command) ->
  id = el.data(command)
  id = ("#" + id) unless id.match(/^[#\.]/)
  object = $(id)
  console.log('Cannot find $("' + id + '") => Declared at data-' + command + ': ' + id ) if object.length == 0
  object

#
# abort if not inside dynbox, thus it's not crud called
#
no_dynbox = (ev) ->
  dynbox(ev).length == 0

dynbox = (ev) ->
  $(ev.target).closest('.js-crud-dynbox')


#
# Hide crud dynbox div
# = link_to t(:cancel), '#', class: 'btn-cancel', data: { hide: true }
#
$(document).on 'mousedown', 'a[data-hide]', (ev) ->
  return if no_dynbox(ev)

  el = $(ev.target)
  $(el).closest('.js-crud-dynbox').collapse('hide')
  $('body').removeClass('ajax-progress')
  second_ajax = false

$(document).on 'click', 'a[data-hide]', (ev) ->
  return if no_dynbox(ev)

  ev.preventDefault()
  ev.stopPropagation()
  false


#
# Self submit form. 
# We cannot use submit event because
# when form is inside other form does not trigger submit event
# 
# example usage:
# = form_with model: @user do |f|
#   = f.submit t(:save), data: { submit-reload: '#js-users' }
#
$(document).on 'mousedown', 'input[type="submit"][data-submit],input[type="submit"][data-submit-reload]', (ev) ->
  return if no_dynbox(ev)

  btn = $(ev.target)
  form = btn.closest('form')
  $('body').addClass('ajax-progress')
  $.ajax
    type: form.prop('method')
    url: form.prop('action')
    data: form.serialize()
  .done (data, textStatus, jqXHR) ->
    obj(btn, 'submit-reload').trigger('reload') if btn.data('submit-reload')
    dynbox(ev).collapse('hide')
  .fail (jqXHR, textStatus, errorThrown) ->
    flash_alert(jqXHR.responseText)
  .always ->
    $('body').removeClass('ajax-progress')
    second_ajax = false

$(document).on 'click', 'input[type="submit"][data-submit],input[type="submit"][data-submit-reload]', (ev) ->
  return if no_dynbox(ev)

  ev.preventDefault()
  ev.stopPropagation()
  false


#
# link update without remote: true
#
# $(document).on 'mousedown', 'a[data-update]', (ev) ->
  # $('body').addClass('ajax-progress')
  # el = $(ev.target)
  # $.get
    # url: el.prop('href')
  # .fail (jqXHR, textStatus, errorThrown) ->
    # flash_alert(jqXHR.responseText)

# $(document).on 'click', 'a[data-update]', (ev) ->
  # ev.preventDefault()
  # ev.stopPropagation()
  # false



#
# Ajax crud helper: data_replace
#
# Usage example: 
#   link_to t(:new_user), new_user_path, data: { remote: true, update: '#js-user' }
#
# Crud example:
#
# 1) Get a new user form and update #js-new-user with a new form
#    link_to t(:new_user), new_user_path, data: { remote: true, update: '#js-new-user' }
#    #js-new-user
#
# 2) Submit a new user form and update #js-users
#    form_with @user, data: { update: '#js-users' }
#
# 3) Update users index #js-users
#    #js-users{ data: { url: users_url }}
#
# 4) Get user edit form
#    link_to @user.name, user_url(@user), data: { remote: true, update '#js-edit-user' }
#    #js-edit-user
#
# 5) Submit user changes and update #js-users list
#    form_with @user, data: { update: '#js-users' }
#
$(document).on 'ajax:success', (ev, request) ->
  el = $(ev.target)

  # alert(JSON.stringify(ev))
  # alert(JSON.stringify(request))
  
  if request
    # old jquery/rails stack
    content = request
  else
    # new jquery/rails stack
    content = ev.detail[2].responseText

  $(document).trigger(el.data('trigger')) if el.data('trigger')

  # form actions
  el.trigger('reset') if el.data('reset')
  Rails.fire(document.querySelector(el.data('submit')), 'submit') if el.data('submit')

  $(el.data('reload')).trigger('reload') if el.data('reload')
  if el.data('delayed-reload')
    setTimeout (-> $(el.data('delayed-reload')).trigger('reload')), 500
  $(el.data('remove')).remove() if el.data('remove')
  $(el.data('clear')).trigger('clear') if el.data('clear')

  # collaps actions
  $(el.data('toggle')).collapse('toggle') if el.data('toggle')
  $(el.data('show')).collapse('show') if el.data('show')
  $(el.data('hide')).collapse('hide') if el.data('hide')
  obj(el, 'update').trigger('update', content) if el.data('update')

  # table related, later can refactor it
  if el.data('append')
    tr = $(el.data('append'))
    append_class = tr.attr('id') + '-append'
    return $('.' + append_class).trigger('remove') if $('.' + append_class).length
    tr_content = "<tr class='js-remove " + append_class + "'><td colspan='" + tr.find('td').size() + "'>" + '<div class="js-remove" style="display: none">' + content + "</div></td></tr>"
    tr.after(tr_content).trigger('turbolinks:load')
    $('div.js-remove').slideDown()

  # don't turn off ajax wait icon
  if el.data('reload') || el.data('delayed-reload')
    second_ajax = true
    return false

  # fix: somehow, when using html(), it will not trigger ajax:complete
  $(document).trigger 'ajax:complete', ev

  # don't do anything if empty url
  return false if el.attr('href') == '#'

#
# Ajax reload support
#
$(document).on 'reload', (ev) ->
  target = $(ev.target)
  url = target.data('url')
  return false unless url?
  return false if url == '#'
  $('body').addClass('ajax-progress')

  $.get
    url: url
    data_type: 'html'
  .done (data) ->
    $(target).html(data).trigger('turbolinks:load')
    $('body').removeClass('ajax-progress')
    second_ajax = false

$(document).on 'mousedown', '.js-trigger-remove', (ev) ->
  # slideup does not work on tr, td, only div inside td
  $('div.js-remove').slideUp ->
    $('.js-remove').remove()

$(document).on 'click', '.js-trigger-remove', (ev) ->
  ev.preventDefault()
  ev.stopPropagation()
  false

#
# Common actions
#
$(document).on 'remove', (ev) ->
  target = $(ev.target)
  return unless target.length

  target.slideUp ->
    target.remove()

$(document).on 'clear', (ev) ->
  target = $(ev.target)
  return unless target.length

  if target.hasClass('collapse')
    target.addClass('js-collapse-autoclean').collapse('hide')
  else
    target.slideUp ->
      target.html('')
  $('body').removeClass('ajax-progress')

$(document).on 'update', (ev, data) ->
  target = $(ev.target)
  return unless target.length

  # honor controller redirect, skip all update
  if data.substr(0,10) == 'Turbolinks'
    second_ajax = true
    return

  hide = target.is(':visible')
  hide = false if target.data('nohide') == true

  if hide
    target.collapse('hide')
  else
    target.addClass('js-crud-dynbox') unless target.hasClass('js-crud-dynbox')
    if target.hasClass('collapse')
      target.html(data).trigger('turbolinks:load').collapse('show')
    else
      target.show()
      target.html(data).trigger('turbolinks:load')


#
# Collapse functions: clean collapse div, 
# needed to reuse same div multiple times for remote
#
$(document).on 'hidden.bs.collapse', '.collapse', (ev) ->
  target = $(ev.target)
  if target.hasClass('js-collapse-autoclean')
    target.html('')
